<template>
    <div>

        <template v-if="sorting_show">
            <van-nav-bar
                    placeholder
                    fixed
                    left-arrow
                    :border='false'
                    :title="'已选择'+checkBox.length+'本'"
                    class="header"
                    z-index="99">
                <template #left>
                    <p class="header-right" @click="sortingLeft">{{future}}</p>
                </template>
                <template #right>
                    <p class="head-right" @click="sortingRight">取消</p>
                </template>
            </van-nav-bar>
        </template>
        <template v-else>
            <div class="book-top">
                <p class="book-title">书架</p>
                <p class="search" @click="searchClick">
                    <img src="../../assets/images/book-icon1.png" alt="" class="search-icon">
                    搜索书架
                </p>
                <p @click="moreClick">
                    <img src="../../assets/images/book-icon.png" alt="" class="book-img">
                </p>
            </div>
            <div class="book-top-list">
                <div>
                    <p class="top-title"><span>{{shelfList.today_read_time?shelfList.today_read_time:'0'}}</span>分钟</p>
                    <p class="top-text">今日已读</p>
                </div>
                <div class="bookbean_icon-img" @click="signClick">
                    <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon">
                    签到送书豆
                </div>
            </div>
        </template>
        <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    :finished-text="finished_text"
                    @load="onLoad"
            >
                <div>
                    <Book1 :sorting_show="sorting_show"
                           :indexList="indexList"
                           @readingClick='readingClick'
                           v-if="appinitList.shelve_style == '0'"></Book1>
                    <Book2 :sorting_show="sorting_show"
                           :indexList="indexList"
                           @readingClick='readingClick'
                           v-else></Book2>
                    <div class="no" v-if="indexList.length == 0">
                        <No title="书架空空~"></No>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>

        <div class="delete-button" v-if="sorting_show" @click="removeClick">
            <p><img src="../../assets/images/icon25.png" alt="" class="icon25"></p>
            <p class="icon25-text">移出书架</p>
        </div>
        <!--        更多弹窗-->
        <van-overlay :show="show" @click="show = false" z-index="99">
            <div class="wrapper">
                <div class="block">
                    <p class="icon21" @click="recordClick">
                        <img src="../../assets/images/icon21.png" alt="">
                        阅读记录
                    </p>
                    <p class="icon21" @click="sortingClick">
                        <img src="../../assets/images/icon22.png" alt="">
                        整理书架
                    </p>
                    <p class="icon21" @click="finishingClick">
                        <img src="../../assets/images/icon23.png" alt="">
                        图书排序
                    </p>
                </div>
            </div>
        </van-overlay>
        <!--        整理书架-->
        <van-action-sheet
                v-model="finishing_show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @select="selectClick"
        />
    </div>
</template>

<script>
    import Book1 from '../../components/BookView/Book1'
    import Book2 from '../../components/BookView/Book2'
    import No from '../../components/No'

    export default {
        components: {
            Book1,
            Book2,
            No,
        },
        name: "Bookcase",
        data() {
            return {
                sorting_show: false,
                show: false,
                finishing_show: false,
                actions: [{id: '1', name: '更新时间优先'}, {id: '0', name: '阅读时间优先'},],
                future: '全选',
                page: 1,
                page_size: 15,
                value: '',
                count: 0,
                shelfList: [],
                indexList: [],
                moreList_show: false,
                loading: false,
                finished: false,
                refreshing: false,
                finished_text: '',
                checkBox: [], //选中的内容
                clickAll: false,//是否全选
                appinitList: []
            }
        },
        created() {
            if (localStorage.getItem('value')) {
                this.value = localStorage.getItem('value')
            } else {
                this.value = 0
            }
            this.shelf()
            this.appinit()
        },
        methods: {
            appinit() {
                this.$axios.post('/api/common/appinit')
                    .then(res => {
                        this.appinitList = res.data.data
                    })
            },

            sortingLeft() {
                if (this.clickAll) {
                    this.future = '全选'
                    this.clickAll = false
                    this.indexList.forEach((value, index) => {
                        value.status = false
                        this.$set(this.indexList, index, value);
                        this.checkBox.push(value)
                    })
                    this.checkBox = []
                } else {
                    this.checkBox = []
                    this.future = '取消全选'
                    this.clickAll = true
                    this.indexList.forEach((value, index) => {
                        value.status = true
                        this.$set(this.indexList, index, value);
                        this.checkBox.push(value)
                    })
                }
            },

            bookClick(item, index) {
                if (!item.status) {
                    if (this.checkBox.length + 1 == this.indexList.length) {
                        this.future = "取消全选"
                        this.clickAll = true
                    } else {
                        this.future = "全选"
                        this.clickAll = false
                    }
                    item.status = true
                    this.$set(this.indexList, index, item);
                    this.checkBox.push(item);
                } else {
                    if (this.checkBox.length - 1 == this.indexList.length) {
                        this.future = "取消全选"
                        this.clickAll = true
                    } else {
                        this.future = "全选"
                        this.clickAll = false
                    }
                    item.status = false
                    this.$set(this.indexList, index, item);
                    this.checkBox = this.checkBox.filter(item => {
                        return item.status
                    })
                }
            },
            readingClick(item, index) {
                if (this.sorting_show) {
                    this.bookClick(item, index)
                } else {
                    this.reading(item)
                }
            },
            reading(item) {
                this.$router.push({
                    path: '/reading',
                    query: {
                        id: String(item.anid),
                        chaps: String(item.chapter.chaps)
                    }
                })
            },
            removeClick() {
                if (this.checkBox.length == 0) {
                    this.$toast('请选择需要移除的书籍！')
                    return
                }
                this.$dialog.alert({
                    title: '移除',
                    message: '确定要将书籍移除吗？',
                    confirmButtonColor: '#4D77FD',
                    cancelButtonColor: '#606266',
                    showCancelButton: true,
                }).then(() => {
                    let id = '';
                    this.checkBox.forEach(item => {
                        id = id.concat(item.anid) + ','
                    })
                    id = id.substring(0, id.lastIndexOf(','));
                    this.$axios.get('/api/bookstores/remove_from_shelve', {
                        params: {
                            id: id,
                        }
                    })
                        .then(res => {
                            this.$toast(res.data.message);
                            this.moreList_show = true
                            this.sorting_show = false
                            this.shelf()
                        })
                })
                    .catch(() => {
                    })


            },
            onRefresh() {
                this.page = 1
                this.checkBox = []
                this.future = "全选"
                this.clickAll = false
                if (this.indexList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.shelf()
            },
            onLoad() {
                if (this.finished) return
                this.checkBox = []
                this.future = "全选"
                this.clickAll = false
                this.page++;
                this.shelf()
            },
            shelf() {
                this.$axios.get('/api/bookstores/shelf', {
                    params: {
                        page: this.page,
                        page_size: this.page_size,
                        sort: this.value,
                    }
                })
                    .then(res => {
                        var res = res.data.data
                        this.count = res.count
                        this.shelfList = res
                        this.refreshing = false
                        this.loading = false;
                        if (this.moreList_show) {
                            this.indexList = res.list
                            this.indexList.forEach(item => {
                                item.status = false
                            })
                            this.moreList_show = false
                        } else {
                            this.indexList = this.indexList.concat(res.list)
                            this.indexList.forEach(item => {
                                item.status = false
                            })
                        }
                        if (this.indexList.length >= this.count) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.pageList.page == 1) {
                            this.finished_text = ''
                        }
                    })
                    .catch(err => {
                        // this.$toast(err.response.data.message);
                    })
            },
            moreClick() {
                this.show = true
            },
            recordClick() {
                this.$router.push({
                    path: '/record'
                })
            },
            searchClick() {
                this.$router.push({
                    path: '/search'
                })
            },
            signClick() {
                this.$router.replace({
                    path: '/welfare'
                })
            },
            finishingClick() {
                this.show = false
                this.finishing_show = true
            },
            selectClick(item) {
                this.value = item.id
                localStorage.setItem('value', this.value)
                this.page = 1;
                this.moreList_show = true
                this.shelf()
            },
            sortingClick() {
                if (this.indexList.length == 0) {
                    this.$toast('暂无书籍可以整理哦！')
                    return
                }
                this.show = false
                this.sorting_show = true
            },
            sortingRight() {
                this.sorting_show = false
                this.checkBox = []
                this.future = "全选"
                this.clickAll = false
                this.indexList.forEach(item => {
                    item.status = false
                })
            }

        }
    }
</script>

<style scoped>
    .icon25 {
        width: 24px;
        height: 27px;
        margin-bottom: 16px;
    }

    .icon25-text {
        color: #D53D3C;
        font-size: 26px;
    }

    .delete-button {
        background: #F2F5F8;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 100;
        padding: 15px;
    }


    .header-right {
        color: #4D77FD;
        font-size: 28px;
    }

    .head-right {
        color: #606266;
        font-size: 28px;
    }

    .icon21 {
        display: flex;
        align-items: center;
        font-size: 28px;
        color: #606266;
        margin-bottom: 40px;
    }

    .icon21:last-of-type {
        margin-bottom: 0 !important;
    }

    .icon21 > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .block {
        padding: 40px;
        background: #fff;
        border-radius: 6px;
        margin-top: 80px;
        margin-right: 50px;
    }

    .wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .top-text {
        font-size: 28px;
        color: #606266;
        margin-top: 20px;
    }

    .top-title {
        color: #606266;
        font-size: 28px;
    }

    .top-title > span {
        color: #0A1C33;
        font-weight: bold;
        font-size: 40px;
    }

    .book-top-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        padding: 0 40px;
    }

    .bookbean_icon-img {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("../../assets/images/sign_bg_btn.png") no-repeat;
        background-size: cover;
        height: 60px;
        width: 200px;
        color: #fff;
        font-size: 26px;
    }

    .bookbean_icon {
        width: 26px;
        height: 27px;
        margin-right: 10px;
    }

    .book-img {
        width: 6px;
        height: 30px;
    }

    .search-icon {
        width: 30px;
        height: 32px;
        margin-right: 15px;
    }

    .search {
        width: 460px;
        height: 60px;
        background: rgba(242, 245, 248, 1);
        border-radius: 30px;
        display: flex;
        align-items: center;
        padding: 0 40px;
        color: #BFC2CC;
        font-size: 28px;
    }

    .book-title {
        font-size: 42px;
        color: #0A1C33;
    }

    .book-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 40px;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 99;
    }
</style>