<template>
    <div class="book-list">
        <template v-for="(item,index) in indexList">
            <div class="book-div" @click="readingClick(item,index)">
                <div class="book_img">
                    <div class="points_relative">
                        <img :src="item.coverpic" alt="" class="book_img1">
                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    </div>
                    <p><img src="../../assets/images/book_shelf_bg2.png" alt="" class="book_shelf_bg2"></p>
                    <p v-if="sorting_show">
                        <img src="../../assets/images/icon24.png" alt="" class="icon24" v-if="!item.status">
                        <img src="../../assets/images/icon26.png" alt="" class="icon24" v-else>
                    </p>
                </div>
                <div class="book_div">
                    <p class="title">{{item.title}}</p>
                    <p class="subtitle" v-if="item.read_chaps !=0">已读{{item.read_chaps}}章/{{item.allchapter}}章</p>
                    <p class="subtitle" v-else>未读</p>
                </div>
            </div>
        </template>
        <div class="book-div" @click="libraryClick" v-if="!sorting_show&& indexList.length != 0">
            <div class="book_img">
                <p class="bookshelf_div"><img src="../../assets/images/bookshelf_add.png" alt="" class="bookshelf_add">
                </p>
                <p><img src="../../assets/images/book_shelf_bg2.png" alt="" class="book_shelf_bg2"></p>
            </div>
            <div class="book_div"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Book1",
        props: {
            sorting_show: Boolean,
            indexList: Array,
        },
        data() {
            return {}
        },
        methods: {
            libraryClick() {
                this.$router.replace({
                    path: '/library'
                })
            },
            readingClick(item, index) {
                this.$emit('readingClick', item, index)
            },
        }
    }
</script>

<style scoped>
    .icon24 {
        position: absolute;
        top: 15px;
        right: 35px;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 100%;
    }

    .book_div {
        height: 70px;
    }

    .bookshelf_div {
        width: 190px;
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F2F5F8;
        border-radius: 10px;
    }

    .bookshelf_add {
        width: 40px;
        height: 40px;
    }

    .book-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 20px;
        margin-bottom: 60px;
    }


    .subtitle {
        padding: 0 10px;
        color: #909399;
        font-size: 22px;
        margin-top: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    .title {
        text-align: left;
        color: #303133;
        font-size: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;

    }

    .book-div {
        margin-top: 40px;
        text-align: center;
        width: 235px;
    }

    .book_shelf_bg2 {
        width: 235px;
        height: 25px;
        position: relative;
        top: -10px;
    }

    .book_img1 {
        width: 190px;
        height: 266px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        display: inline-block;
        border-radius: 10px;
    }

    .book_img {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }
</style>