<template>
    <div class="book-list">
        <template v-for="(item,index) in indexList">
            <div class="book-left" @click="readingClick(item,index)">
                <p class="points_relative">
                    <img :src="item.coverpic" alt="" class="book_img1">
                    <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                </p>
                <div>
                    <p class="title">{{item.title}}</p>
                    <p class="subtitle">
                        <img src="../../assets/images/author_icon.png" alt="" class="author_icon">
                        <span>{{item.author}}</span>
                    </p>
                    <p class="text" v-if="item.read_chaps !=0">已读{{item.read_chaps}}章/{{item.allchapter}}章</p>
                    <p class="text" v-else>未读</p>
                </div>
                <p v-if="sorting_show">
                    <img src="../../assets/images/icon24.png" alt="" class="icon24" v-if="!item.status">
                    <img src="../../assets/images/icon26.png" alt="" class="icon24" v-else>
                </p>
            </div>
        </template>
        <div class="bookshelf_add" @click="libraryClick" v-if="!sorting_show && indexList.length != 0">
            <img src="../../assets/images/bookshelf_add.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Book2",
        props: {
            sorting_show: Boolean,
            indexList: Array,
        },
        data() {
            return {
                status: false,
            }
        },
        methods: {
            libraryClick() {
                this.$router.replace({
                    path: '/library'
                })
            },
            readingClick(item, index) {
                this.$emit('readingClick', item, index)
            },
        }
    }
</script>

<style scoped>

    .icon24 {
        position: absolute;
        top: 50%;
        transform: translateX(-50%);
        right: 0;
        width: 30px;
        height: 30px;
    }

    .bookshelf_add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 670px;
        height: 88px;
        background: rgba(242, 245, 248, 1);
        border-radius: 44px;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .bookshelf_add > img {
        width: 40px;
        height: 40px;
    }

    .text {
        color: #909399;
        font-size: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 400px;
    }

    .subtitle {
        display: flex;
        align-items: center;
        color: #909399;
        font-size: 24px;
        margin: 22px 0;

    }

    .subtitle > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 260px;
    }

    .title {
        color: #303133;
        font-size: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 420px;
    }

    .book-left {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        position: relative;
    }

    .author_icon {
        width: 20px;
        height: 22px;
        margin-right: 16px;
    }

    .book_img1 {
        width: 100px;
        height: 140px;
        margin-right: 28px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        border-radius: 10px;
    }

    .book-list {
        padding: 40px;
    }
</style>